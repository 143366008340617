import { useCallback, useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../../store/hooks'

import getFinalPermissions from '../../../../utils/permissions/getFinalPermissions'
import getFormattedGMTtoDMYDate from '../../../../utils/date/getFormattedGMTtoDMYDate'
import getTemplateOptionsData from '../../../../utils/data/getTemplateOptionsData'
import invoiceBatchService from '../../../../services/payoutServices/invoiceBatchService'
import listFileBatchService from '../../../../services/payoutServices/listFileBatchService'
import removeSpaces from '../../../../utils/commons/removeSpaces'
import validPermittedServices from '../../../../utils/permissions/validPermittedServices'
import useBatchToast from './useBatchToast'

import { InitialFilterData } from '../../../../constants/initialData'
import type { DropdownOptionType, PayoutDataType, TBatchPayoutData, TUploadedFileStatus } from '../../../../../typings/types'
import processStatusData from '../../../../constants/processStatusData'
import processStatusService from '../../../../services/payoutServices/processStatusService'
import { notify } from '../../../../utils/commons'
import { useBasePath, useModal } from '../../../../hooks'
import { type AuthState } from '../../../../store/slices/auth'

const getDataFromAuthState = ({
  authState,
  basePath
}: {
  authState: AuthState
  basePath: string
}) => {
  const { company, services, servicesBySubMenu, user } = authState
  const files = (company ?? []).map(company => company.files).flat()
  const templates = getTemplateOptionsData(company)
  const firstTemplate = templates.length === 1 ? templates[0] : undefined
  const companyId = company?.length === 1 ? company[0].company_id : undefined
  const timezoneCode = company?.length === 1 ? company[0].time_zone_cod : ''
  const permissions = getFinalPermissions(basePath, services, servicesBySubMenu, user?.role_id ?? '')
  const strExtensions = files.find(file => file.file_cod === firstTemplate?.name)?.file_ext
  const templateExtensions = removeSpaces(strExtensions).split(',')

  return {
    ...authState,
    files,
    templates,
    firstTemplate,
    companyId,
    timezoneCode,
    permissions,
    templateExtensions
  }
}

const useBatch = () => {
  const processStatusTimerRef = useRef<NodeJS.Timer>()
  const basePath = useBasePath()
  const { setModalData } = useModal()
  const authState = useAppSelector(state => state.authReducer)
  const {
    templates,
    firstTemplate,
    user,
    companyId,
    timezoneCode,
    permissions,
    templateExtensions
  } = getDataFromAuthState({
    authState,
    basePath
  })
  const [uploadedFile, setUploadedFile] = useState<File>()
  const [uploadedFileStatus, setUploadedFileStatus] = useState<TUploadedFileStatus>()
  const [offset, setOffset] = useState(0)
  const [filterData, setFilterData] = useState<any>(InitialFilterData)
  const [payoutsList, setPayoutsList] = useState<TBatchPayoutData[]>([])
  const [areLoadingPayoutsList, setAreLoadingPayoutsList] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(firstTemplate)
  const [fetchedPayouts, setFetchedPayouts] = useState<Record<string, PayoutDataType>>({})
  const [selectedPayouts, setSelectedPayouts] = useState<Record<string, PayoutDataType>>({})
  const [isFetchingPayout, setIsFetchingPayout] = useState(false)
  const [lastSelectedPayoutId, setLastSelectedPayoutId] = useState<string>()
  const [isLoadingDispersion, setIsLoadingDispersion] = useState(false)
  const {
    loadingToastList,
    batchToastList,
    canViewToast,
    toastClassName,
    getToastListFileBatch
  } = useBatchToast({ fileBatchList: payoutsList })
  const lastSelectedPayout = lastSelectedPayoutId ? selectedPayouts[lastSelectedPayoutId] : undefined
  // const firstTemplateList = templateList.length === 1 ? templateList[0] : undefined
  // const [newPayoutId, setNewPayoutId] = useState('')
  // const [currentPayout, setCurrentPayout] = useState<PayoutDataType>(InitialPayoutData)
  // const [, setIsDispersionStateLoading] = useState<boolean>(true)
  // const [nativeData, setNativeData] = useState<any[]>([])
  // const [fileBatchListSize, setFileBatchListSize] = useState<number>(0)
  // const [templateExtensions, setTemplateExtensions] = useState<string[]>([])
  // const [uploadFileIdSelected, setUploadFileIdSelected] = useState<string>('')
  // const [isActiveUploadButton, setIsActiveUploadButton] = useState<boolean>(false)

  // const permissions = getFinalPermissions(basePath, servicesRedux, servicesBySubMenuRedux, user?.role_id ?? '')
  // const strExtensions = files.find(file => file.file_cod === selectedTemplate?.name)?.file_ext
  const isActiveUploadButton = (templates.length < 2 || Boolean(selectedTemplate)) && !areLoadingPayoutsList && !isLoadingDispersion

  /*
     * Función que reinicia los filtros
    */
  const restartFilterData = () => { setFilterData(InitialFilterData) }

  /*
     * Función que maneja la petición de servicio que obtiene la lista actual
     * de archivos batch cargados usando un servicio
    */
  const getListFileBatch = useCallback(
    async (args?: {
      filterData?: any
      offset?: number
    }) => {
      const canListFileBatch = validPermittedServices('listFileBatch', permissions)
      if (!canListFileBatch) {
        setModalData({ isOpen: true, type: 'serviceError', service: 'listFileBatch' })
        return
      }

      try {
        setAreLoadingPayoutsList(true)
        const listFileBatchPayload = {
          company_id: companyId,
          limit_int: 20,
          offset_int: args?.offset ?? 0,
          orderby: 'batch.file.request_dtm',
          orderdir_str: 'desc',
          user,
          filterData: {
            processStatus: args?.filterData?.processStatus?.toUpperCase() ?? 'ALL',
            nameId: args?.filterData?.nameId ?? '',
            calendar: {
              iniDte: getFormattedGMTtoDMYDate(args?.filterData?.calendar?.[0]) ?? '',
              finDte: getFormattedGMTtoDMYDate(args?.filterData?.calendar?.[1]) ?? ''
            }
          }
        }
        const response = await listFileBatchService(listFileBatchPayload)
        if (!response?.data?.['list-file-batch'] || !Array.isArray(response.data['list-file-batch'])) return

        return response.data['list-file-batch']
      } catch (error) {
        setModalData({ isOpen: true, type: 'conexionError', service: '' })
        setSelectedPayouts({})
        setFetchedPayouts({})
      } finally {
        setAreLoadingPayoutsList(false)
      }
    },
    [companyId, permissions, user]
  )

  /*
     * Función que maneja la petición de servicio de subida de archivos por el botón de carga
    */
  const createNewFileBatch = async (batchData: any) => {
    if (!validPermittedServices('invoiceBatch', permissions)) {
      setModalData({ isOpen: true, type: 'serviceError', service: 'invoiceBatch' })
      setSelectedPayouts({})
      setFetchedPayouts({})
      setIsLoadingDispersion(false)
      return
    }

    try {
      const response = await invoiceBatchService(batchData, user)
      const { status, data } = response

      if (status === 204) {
        setUploadedFileStatus('already')
      } else if (status === 202) {
        const dataAsJson = JSON.parse(new TextDecoder().decode(data))
        const { payout_id: createdPayoutId } = dataAsJson
        restartFilterData()
        setOffset(0)
        setSelectedPayouts({})
        setFetchedPayouts({})
        const payoutsList = await getListFileBatch({
          filterData: InitialFilterData
        })
        if (!payoutsList) return

        setPayoutsList(payoutsList)
        setUploadedFileStatus('success')
        const createdPayout = payoutsList.find(payout => payout.payout_id === createdPayoutId)
        if (!createdPayout) return
        await handleClickUploadFile(createdPayout, false)

        // list again until new payout has state WAITING
        const intervalId = setInterval(() => {
          void getListFileBatch({ filterData: InitialFilterData }).then(payoutsList => {
            if (!payoutsList) return

            setPayoutsList(payoutsList)
            const createdPayout = payoutsList.find(payout => payout.payout_id === createdPayoutId)
            if (!createdPayout) return

            void handleClickUploadFile(createdPayout, false)
            if (createdPayout.status_cod !== 'WAITING') return

            clearInterval(intervalId)
          })
        }, 1000)
      }

      if (!canViewToast || toastClassName === 'cant-view-toast') return
      void getToastListFileBatch()
    } catch (error: any) {
      console.log(error)
      setUploadedFileStatus('error')
    }
  }

  /*
     * Función que maneja el botón de subida de archivos
    */
  const handleChangeUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    if (!event.target.files || event.target.files.length === 0) return

    const file = event.target.files[0]

    if (!file) return

    setUploadedFile(file)
    const { name: fileName } = file

    const isValidFileFormat = templateExtensions.some(substring => fileName.includes(substring))
    if (!isValidFileFormat) {
      setUploadedFileStatus('extensionError')
      return
    }

    void createNewFileBatch({
      file_bin: file,
      file_cod: selectedTemplate?.title,
      company_id: selectedTemplate?.companyId,
      user_id: user?.user_id
    })
  }

  /*
     * Función que maneja el cambio de valor del select template cambiando el estado de
     * selectedTemplate, activando el botón de subida de archivo y reiniciando el estado
     * del proceso de subida de archivo
    */
  const handleChangeSelectTemplate = (option: DropdownOptionType) => {
    setSelectedTemplate(option)
    setUploadedFileStatus(undefined)
  }

  /*
     * Función que cierra el estado de proceso de subida de archivo al clickear en el ícono
     * del mensaje
    */
  const handleClickCloseUploadFileStatus = () => { setUploadedFileStatus(undefined) }

  const processStatusPayout = async (payout: PayoutDataType, enableLoading = true) => {
    try {
      if (enableLoading) setIsFetchingPayout(true)
      const { data } = await processStatusService(payout, user)
      if (data === 'OK') return
      return data
    } catch (error) {
      setModalData({ isOpen: true, type: 'conexionError', service: 'processStatus' })
    } finally {
      if (enableLoading) setIsFetchingPayout(false)
    }
  }

  const runProcessStatusInterval = async (payouts: PayoutDataType[]) => {
    if (processStatusTimerRef.current) clearInterval(processStatusTimerRef.current)
    if (payouts.length === 0) return

    const statusCode = payouts[0].status_cod
    const shouldCheckStatusCodeChanges = ['PROCESSING', 'CHECKING', 'DISPERSION'].includes(statusCode)
    if (!shouldCheckStatusCodeChanges) return

    const isBeingProcessed = ['PROCESSING', 'CHECKING'].includes(statusCode)
    processStatusTimerRef.current = setInterval(() => {
      void Promise.all(payouts.map(async payout => await processStatusPayout(payout, false))).then(processedPayouts => {
        if (processedPayouts.length === 0) return

        const newFetchedPayouts: Record<string, PayoutDataType> = {}
        processedPayouts.forEach(payout => {
          if (!payout) return

          const { payout_id: payoutId } = payout
          newFetchedPayouts[payoutId] = payout
        })

        setFetchedPayouts(newFetchedPayouts)
      })
    }, isBeingProcessed ? 5000 : 300000)
  }

  /*
     * Función que maneja el click de la lista de archivos subidos y setea un nuevo id al estado
     * uploadFileIdSelected
    */
  const handleClickUploadFile = async (payout: PayoutDataType, areBeingGrouped = true) => {
    const { payout_id: payoutId } = payout

    clearInterval(processStatusTimerRef.current)
    if (!areBeingGrouped) {
      setSelectedPayouts({
        [payoutId]: payout
      })
      setLastSelectedPayoutId(payoutId)

      const processedPayout = await processStatusPayout(payout)
      if (!processedPayout) return
      setFetchedPayouts({
        [payoutId]: processedPayout
      })

      await runProcessStatusInterval([payout])
      return
    }

    const isEmptySelectedPayouts = Object.keys(selectedPayouts).length === 0
    if (isEmptySelectedPayouts) {
      setSelectedPayouts({
        [payoutId]: payout
      })
      setLastSelectedPayoutId(payoutId)

      const processedPayout = await processStatusPayout(payout)
      if (!processedPayout) return
      setFetchedPayouts({
        [payoutId]: processedPayout
      })

      await runProcessStatusInterval([payout])
      return
    }

    const isAlreadyPayoutSelected = payoutId in selectedPayouts
    if (isAlreadyPayoutSelected) {
      setLastSelectedPayoutId(payoutId)
      const hasOnePayoutSelected = Object.keys(selectedPayouts).length === 1
      if (hasOnePayoutSelected) return

      const { [payoutId]: removedSelectedPayout, ...newSelectedPayouts } = selectedPayouts
      const { [payoutId]: removedFetchedPayout, ...newFetchedPayouts } = fetchedPayouts
      setSelectedPayouts(newSelectedPayouts)
      setFetchedPayouts(newFetchedPayouts)

      const payouts = Object.values(newSelectedPayouts)
      await runProcessStatusInterval(payouts)
      return
    }

    const { status_cod: payoutStatusCode, origin_cod: payoutOriginCode } = payout
    const currentStatus = processStatusData.find(process => process.name.toLowerCase() === payoutStatusCode)
    const safePayoutStatusCode = currentStatus?.name ?? payout.status_cod
    if (lastSelectedPayoutId && lastSelectedPayout && lastSelectedPayout.origin_cod !== payoutOriginCode) {
      notify.error({
        title: 'Payouts con diferente origen',
        description: 'No se puede agrupar payouts con diferente tipo de origen'
      })
      return
    }

    if (lastSelectedPayoutId && lastSelectedPayout && lastSelectedPayout.status_cod !== safePayoutStatusCode) {
      notify.error({
        title: 'Payouts con diferente estado',
        description: 'No se puede agrupar payouts con estados diferentes'
      })
      return
    }

    const updatedSelectedPayouts = {
      ...selectedPayouts,
      [payoutId]: payout
    }
    setSelectedPayouts(updatedSelectedPayouts)
    setLastSelectedPayoutId(payoutId)

    const processedPayout = await processStatusPayout(payout)
    if (!processedPayout) return
    setFetchedPayouts(previousFetchedPayouts => ({
      ...previousFetchedPayouts,
      [payoutId]: processedPayout
    }))

    const payouts = Object.values(updatedSelectedPayouts)
    await runProcessStatusInterval(payouts)
    // // Try to group by status
    // setCurrentPayout(payout)
    // if (!areBeingGrouped) {
    //   // setCurrentPayout(payout)
    //   // setIsLoadingDispersion(payoutId !== '')
    //   // setUploadFileIdSelected(payoutId)
    //   setSelectedPayouts({
    //     [payoutId]: payout
    //   })
    //   setLastSelectedPayoutId(payoutId)
    //   try {
    //     const { data } = await processStatusService(payout, reduxUser)
    //     if (data === 'OK') return

    //     setFetchedPayouts({
    //       [payoutId]: payout
    //     })
    //   } catch (error) {
    //     setModalData({ isOpen: true, type: 'conexionError', service: 'processStatus' })
    //   }
    // } else {
    //   // setIsLoadingDispersion(payoutId !== '')
    //   // setUploadFileIdSelected(payoutId)

    //   const isEmptySelectedPayouts = Object.keys(selectedPayouts).length === 0
    //   const lastSelectedPayout = lastSelectedPayoutId ? selectedPayouts[lastSelectedPayoutId] : undefined
    //   console.log({
    //     lastSelectedPayoutId,
    //     lastSelectedPayout
    //   })
    //   if (isEmptySelectedPayouts) {
    //     setSelectedPayouts(previousSelectedPayouts => ({
    //       ...previousSelectedPayouts,
    //       [payoutId]: payout
    //     }))
    //     setLastSelectedPayoutId(payoutId)
    //     try {
    //       const { data } = await processStatusService(payout, reduxUser)
    //       if (data === 'OK') return

    //       setFetchedPayouts({
    //         [payoutId]: payout
    //       })
    //     } catch (error) {
    //       setModalData({ isOpen: true, type: 'conexionError', service: 'processStatus' })
    //     }
    //   } else if (lastSelectedPayout?.status_cod === safePayoutStatusCode) {
    //     const foundPayout = payoutId in selectedPayouts
    //     if (!foundPayout) {
    //       setSelectedPayouts(previousSelectedPayouts => ({
    //         ...previousSelectedPayouts,
    //         [payoutId]: payout
    //       }))
    //       setLastSelectedPayoutId(payoutId)
    //       try {
    //         const { data } = await processStatusService(payout, reduxUser)
    //         if (data === 'OK') return

    //         setFetchedPayouts({
    //           ...fetchedPayouts,
    //           [payoutId]: payout
    //         })
    //       } catch (error) {
    //         setModalData({ isOpen: true, type: 'conexionError', service: 'processStatus' })
    //       }
    //     } else {
    //       const { [payoutId]: removedFetchedPayout, ...newFetchedPayouts } = fetchedPayouts
    //       const { [payoutId]: removedSelectedPayout, ...newSelectedPayouts } = selectedPayouts
    //       setSelectedPayouts(newSelectedPayouts)
    //       setFetchedPayouts(newFetchedPayouts)
    //       setLastSelectedPayoutId(payoutId)
    //     }

    //     // setSelectedPayouts(previousSelectedPayouts => {
    //     //   const foundPayout = payoutId in previousSelectedPayouts
    //     //   if (!foundPayout) {
    //     //     return {
    //     //       ...previousSelectedPayouts,
    //     //       [payoutId]: payout
    //     //     }
    //     //   }

    //     //   const { [payoutId]: removedPayout, ...newSelectedPayouts } = previousSelectedPayouts
    //     //   return newSelectedPayouts
    //     // })
    //     // setLastSelectedPayoutId(payoutId)
    //   } else {
    //     alert('no se puede agrupar')
    //   }
    // }
  }

  /*
     * Función que maneja el scroll vertical de la sección de lista de archivos cargados
     * y acciona cuando está en el fondo de esta y además cuando el tamaño de la lista de
     * archivos sigue siendo mayor
    */
  const handleScrollUploadFiles = async (event: any) => {
    const delta = 1
    const target = event.target
    const isBottom = (event.target.scrollHeight - target.scrollTop - delta) <= target.clientHeight

    const newOffset = offset + 20
    if (!isBottom || payoutsList.length < newOffset) return

    const payoutsListToAppend = await getListFileBatch({
      offset: newOffset,
      filterData
    })
    if (!payoutsListToAppend) return

    setOffset(newOffset)
    setPayoutsList(previousPayoutsList => [...previousPayoutsList, ...payoutsListToAppend])
  }

  /*
     * Función que maneja la habilitación y deshabilitación del botón de subida de archivos y de la lista de archivos,
     * cuando el componente de estado de dispersión está cargando
    */
  // const handleDispersionLoadingDone = (isLoadingDispersionDone: boolean) => {
  //   setIsLoadingDispersion(!isLoadingDispersionDone)
  // }

  /*
     * Función que actualiza el estado de dispersión del archivo seleccionado
    */
  const reloadBatchDispersionState = async () => {
    try {
      restartFilterData()

      // call again to list file batch (for the payouts list)
      const updatedPayoutsList = await getListFileBatch({
        filterData
      })
      if (!updatedPayoutsList || updatedPayoutsList.length === 0) return

      setOffset(0)
      setPayoutsList(updatedPayoutsList)
      // call again to process status of selected payouts
      const payoutIds = Object.keys(selectedPayouts)
      const updatedSelectedPayoutsList = updatedPayoutsList.filter(payout => payoutIds.includes(payout.payout_id))
      const updatedSelectedPayouts: Record<string, PayoutDataType> = {}

      updatedSelectedPayoutsList.forEach(payout => {
        const { payout_id: payoutId } = payout
        updatedSelectedPayouts[payoutId] = payout
      })

      const processStatuses = await Promise.all(updatedSelectedPayoutsList.map(async payout => await processStatusService(payout, user)))
      if (processStatuses.length === 0) return

      const updatedFetchedPayouts: Record<string, PayoutDataType> = {}

      processStatuses.forEach(processStatus => {
        if (!processStatus?.data) return

        const payout = processStatus.data
        const { payout_id: payoutId } = payout
        updatedFetchedPayouts[payoutId] = payout
      })

      setSelectedPayouts(updatedSelectedPayouts)
      setFetchedPayouts(updatedFetchedPayouts)
      void runProcessStatusInterval(updatedSelectedPayoutsList)
    } catch (error) {
      console.log(error)
    } finally {
      setIsFetchingPayout(false)
      setAreLoadingPayoutsList(false)
    }
  }

  const handleFilterSubmit = async (filterData: any) => {
    clearInterval(processStatusTimerRef.current)
    setSelectedPayouts({})
    setFetchedPayouts({})
    setFilterData(filterData)

    const table = document.getElementsByClassName('batchupload__uploadfiles')[0]
    if (table) table.scrollTop = 0

    const payoutsList = await getListFileBatch({
      filterData
    })
    if (!payoutsList) return
    setOffset(0)
    setPayoutsList(payoutsList)
  }

  // /*
  //    * Efecto que setea las extensiones de archivo permitidas
  //   */
  // // useEffect(() => {
  // //   const strExtensions = files.find(file => file.file_cod === selectedTemplate)?.file_ext
  // //   const arrExtensions = removeSpaces(strExtensions).split(',')

  // //   setTemplateExtensions(arrExtensions)
  // //   }, [selectedTemplate]); // eslint-disable-line

  // /*
  //    * Efecto que deshabilita el botón de subida de archivos cuando no hay selección alguna
  //    * o la lista de archivos subidos está actualizandose
  //   */
  // // useEffect(() => {
  // //   const isActiveUploadButton = (selectedTemplate !== '' || templateList?.length < 2) && !fileBatchListIsLoading && !isLoadingDispersion

  // //   setIsActiveUploadButton(isActiveUploadButton)
  // //   }, [selectedTemplate, fileBatchListIsLoading, isLoadingDispersion]); // eslint-disable-line

  // /*
  //    * Efecto que detecta si el modal apareció para automáticamente deseleccionar el archivo cargado de la lista
  //   */
  // // useEffect(() => {
  // //   if (
  // //     modalData.isOpen && (
  // //       modalData.service === 'listFileBatch' ||
  // //               modalData.service === 'invoiceBatch' ||
  // //               modalData.service === 'processStatus'
  // //     )
  // //   ) {
  // //     cleanBatchDispersionState()
  // //     setIsLoadingDispersion(false)
  // //   };
  // //   }, [modalData.isOpen]); // eslint-disable-line

  // // useEffect(() => {
  // //   setFileBatchListIsLoading(isDispersionStateLoading)
  // // }, [isDispersionStateLoading])

  /*
     * Efecto que maneja acciones iniciales
    */
  useEffect(() => {
    void getListFileBatch().then(payoutsList => {
      if (!payoutsList) return
      setPayoutsList(payoutsList)
    })

    return () => {
      clearInterval(processStatusTimerRef.current)
    }
  }, [])

  return {
    timeZoneCode: timezoneCode,
    uploadedFile,
    uploadedFileStatus,
    templateExtensions,
    fileBatchList: payoutsList,
    isActiveUploadButton,
    templateList: templates,
    fileBatchListIsLoading: areLoadingPayoutsList,
    filterData,
    // newPayoutId,
    loadingToastList,
    batchToastList,
    toastClassName,
    canViewToast,
    selectedPayouts,
    fetchedPayouts,
    isFetchingPayout,

    // Functions States
    // setCurrentPayout,
    // setIsDispersionStateLoading,
    setSelectedPayouts,
    setIsFetchingPayout,
    setAreLoadingPayoutsList,

    // Functions
    handleChangeUploadFile,
    handleChangeSelectTemplate,
    handleClickCloseUploadFileStatus,
    handleClickUploadFile,
    handleScrollUploadFiles,
    // handleDispersionLoadingDone,
    reloadBatchDispersionState,
    handleFilterSubmit
  }
}

export default useBatch
