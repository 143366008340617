import Button from '../Button/Button'
import DatePicker from '../DatePicker/DatePicker'
import Dropdown from '../Dropdown/Dropdown'
import Icon from '../Icon/Icon'
import InputField from '../InputField/InputField'

import getDropdownFilterOptionsData from '../../utils/data/getDropdownFilterOptionsData'

import invoiceProcessStatusData from '../../constants/invoiceProcessStatusData'
import processStatusData from '../../constants/processStatusData'

import './Filter.css'
import type { DropdownOptionType } from '../../../typings/types'

interface IFilterViewProps {
    filterRef: React.LegacyRef<HTMLDivElement> | undefined
    filterData: any
    dropdownType: 'payout' | 'invoice'
    type: 'primary' | 'secondary' | 'tertiary' | 'red'
    size: 'small' | 'regular' | 'large'
    isOpenFilters: boolean
    canOnlySelectSameMonth: boolean
    setIsOpenCalendar: any
    handleOpenFilters: React.MouseEventHandler<HTMLButtonElement> | undefined
    handleProcessStatusFilterChange: (option: DropdownOptionType) => void
    handleCalendarFilterChange: any
    handleNameIdFilterChange: React.ChangeEventHandler<HTMLInputElement> | ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
    handleCleanFiltersClick: React.MouseEventHandler<HTMLButtonElement> | undefined
    handleSubmitFiltersClick: React.MouseEventHandler<HTMLButtonElement> | undefined
};

const FilterView = ({
  filterRef,
  filterData,
  dropdownType,
  type,
  size,
  isOpenFilters,
  canOnlySelectSameMonth,
  setIsOpenCalendar,
  handleOpenFilters,
  handleProcessStatusFilterChange,
  handleCalendarFilterChange,
  handleNameIdFilterChange,
  handleCleanFiltersClick,
  handleSubmitFiltersClick
}: IFilterViewProps) => {
  let statusData = processStatusData

  if (dropdownType === 'invoice') {
    statusData = invoiceProcessStatusData
  };

  const dropdownOptions = getDropdownFilterOptionsData(statusData, dropdownType)
  const dropdownDefaultOption = { id: dropdownOptions?.length, name: 'all', title: 'Todos' }
  const dropdownValue = dropdownOptions?.find((statusData: any) => statusData?.name === filterData?.processStatus)?.title

  return (
    <div className="filter">
      <Button
        type={type}
        text="Filtro"
        icon={<Icon type="filter" />}
        size={size}
        isFitContent
        onClick={handleOpenFilters}
      />
      {
        isOpenFilters && (
          <div ref={filterRef} className="filter__modal">
            <span className="filter__title">Filtros</span>
            <div className="filter__content">
              <div className="filter__option filter__option--dropdown">
                <label className="filter__option__label">Estado:</label>
                <Dropdown
                  options={dropdownOptions}
                  defaultOption={dropdownDefaultOption}
                  size="large"
                  value={dropdownValue}
                  isDefaultOptionHide={false}
                  onChange={handleProcessStatusFilterChange}
                  optionKey='name'
                />
              </div>
              <div className="filter__option filter__option--calendar">
                <label className="filter__option__label">Fecha:</label>
                <DatePicker
                  canUnselect
                  value={filterData?.calendar}
                  setCalendarDate={handleCalendarFilterChange}
                  setIsOpenCalendar={setIsOpenCalendar}
                />
              </div>
              <div className="filter__option filter__option--input">
                <label className="filter__option__label">Nombre archivo o ID:</label>
                <InputField
                  name="filterFile"
                                    style="secondary" //eslint-disable-line
                  value={filterData?.nameId}
                  onChange={handleNameIdFilterChange}
                />
              </div>
              <div className="filter__buttons">
                <Button
                  text="Limpiar"
                  type="secondary"
                  size="small"
                  isFitContent
                  onClick={handleCleanFiltersClick}
                />
                <Button
                  text="Filtrar"
                  type="primary"
                  size="small"
                  isFitContent
                  onClick={handleSubmitFiltersClick}
                />
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default FilterView
