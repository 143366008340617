import Card from '../../../../components/Card/Card'
import Icon from '../../../../components/Icon/Icon'
import InputField from '../../../../components/InputField/InputField'
import Table from '../../../../components/Table/Table'

interface ICustomersViewProps {
    timeZoneCode: string
    data: any
    isLoadingCustomersTable: boolean
    handleScrollCustomersTableData: React.UIEventHandler<HTMLTableSectionElement> | undefined
    handleCustomersFinderValue: React.ChangeEventHandler<HTMLInputElement>
    handleCustomersTableDataClick: any
};

const CustomersView = ({
  timeZoneCode,
  data,
  isLoadingCustomersTable,
  handleScrollCustomersTableData,
  handleCustomersFinderValue,
  handleCustomersTableDataClick
}: ICustomersViewProps) => {
  const visibleBodyData = data?.body?.length > 0 ? Object.keys(data?.body?.[0]).filter(key => key !== 'companyId') : undefined

  return (
    <div className="view">
      <div className="view__header">
        <h1>Clientes</h1>
        <h2>Home - Dispersión - Clientes</h2>
      </div>
      <div className="view__body">
        <Card
          timeZoneCode={timeZoneCode}
          headerChildren={
            <InputField
              name="customersFinder"
              placeholder="Nombre, id o #Documento"
              icon={<Icon type="search" />}
              hasErrorMessage={false}
              hasDebounce
              onChange={handleCustomersFinderValue}
            />
          }
          // eslint-disable-next-line
          children={
            <Table
              data={data}
              isLoadingTableData={isLoadingCustomersTable}
              handleScrollTableData={handleScrollCustomersTableData}
              handleTableDataClick={handleCustomersTableDataClick}
              visibleBodyData={visibleBodyData}
            />
          }
        />
      </div>
    </div>
  )
}

export default CustomersView
