import { useContext, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../store/hooks'
import { useLocation, useParams } from 'react-router-dom'
import moment from 'moment'

import getCapitalizedString from '../../../../utils/commons/getCapitalizedString'
import getCurrencySymbolFromCode from '../../../../utils/money/getCurrencySymbolFromCode'
import getFinalPermissions from '../../../../utils/permissions/getFinalPermissions'
import getFormattedDate from '../../../../utils/date/getFormattedDate'
import getFormattedMoney from '../../../../utils/money/getFormattedMoney'
import getTimeZoneHours from '../../../../utils/date/getTimeZoneHours'
import getUtcOffHours from '../../../../utils/date/getUtcOffHours'
import invoiceDetailDataService from '../../../../services/payoutServices/invoiceDetailDataService'
import transactionReportService from '../../../../services/payoutServices/transactionReportService'
import validPermittedServices from '../../../../utils/permissions/validPermittedServices'

import ModalContext from '../../../../contexts/ModalContext'

import processStatusData from '../../../../constants/processStatusData'
import { useBasePath } from '../../../../hooks'

const useInvoiceDetail = () => {
  const basePath = useBasePath()
  const { companyId, invoiceId } = useParams()
  const companyData = useAppSelector((state) => state.authReducer.company)

  const reduxUser = useAppSelector(state => state.authReducer.user)
  const servicesRedux = useAppSelector(state => state.authReducer.services)
  const servicesBySubMenuRedux = useAppSelector(state => state.authReducer.servicesBySubMenu)

  const location = useLocation()
  const permissions = getFinalPermissions(basePath, servicesRedux, servicesBySubMenuRedux, reduxUser?.role_id ?? '')

  const [invoiceData, setInvoiceData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { setModalData } = useContext(ModalContext)
  const timeZoneCode = companyData?.length === 1 ? companyData[0].time_zone_cod : ''
  const timeZoneHours = getTimeZoneHours(timeZoneCode)
  const utcOffHours = getUtcOffHours(timeZoneHours)

  const handleCreateTransactionReport = async () => {
    try {
      const date = new Date()
      const momentDate = moment(date)?.utcOffset(utcOffHours)?.format('DDMMYYYYHHmmss')
      const response = await transactionReportService(invoiceData?.payoutId, reduxUser)
      const { data } = response
      const currentUrl = window?.URL?.createObjectURL(new Blob([data]))
      const link = document?.createElement('a')

      link.setAttribute('href', currentUrl)
      link.setAttribute('target', '_blank')
      link.setAttribute('download', `transaction-report-${momentDate}.pdf`)

      document?.body?.appendChild(link)

      link?.click()
    } catch (error: any) {
      setModalData({ isOpen: true, type: 'conexionError', service: '' })
    };
  }

  useEffect(() => {
    const getFormattedInvoiceData = (data: any) => {
      if (!data) return

      const invoiceStatus = processStatusData?.find((process: any) => process?.name?.toUpperCase() === data?.status_cod || process?.code?.toUpperCase() === data?.status_cod)
      const invoiceStatusName = data?.status_cod
      const invoiceStatusTitle = getCapitalizedString(invoiceStatus?.altTitle ?? data?.status_cod)
      const invoiceStatusColor = invoiceStatus?.color

      const getAccountType: any = {
        SAVING: 'Ahorro',
        CHECKING: 'Corriente'
      }

      return {
        invoiceId: data?.invoice_id ?? '',
        status: {
          name: invoiceStatusName,
          title: invoiceStatusTitle,
          color: invoiceStatusColor
        },
        registerDate: moment(getFormattedDate(data?.created_dtm, timeZoneCode))?.format('DD/MM/YYYY HH:mm:ss') ?? '',
        transactionDate: moment(getFormattedDate(data?.status_transaction_dtm, timeZoneCode))?.format('DD/MM/YYYY HH:mm:ss') ?? '',
        externalId: data?.external_invoice_id ?? '',
        payoutId: data?.payout_id ?? '',
        bankName: data?.customer?.bank_account?.bank_name_str ?? '',
        accountType: getAccountType?.[data?.customer?.bank_account?.type] ?? '',
        accountNumber: data?.customer?.bank_account?.bank_account_number_str ?? '',
        accountCciNumber: data?.customer?.bank_account?.cci_str ?? '',
        bankOperation: data?.bank_operation_cod ?? '',
        observation: data?.message_str ?? '',
        bankCertificate: data?.bank_file_path ?? '',
        totalAmount: getCurrencySymbolFromCode(data?.currency_cod) + ' ' + getFormattedMoney(data?.total_dcm),
        clientId: data?.customer?.external_customer_id ?? '',
        clientName: data?.customer?.name_str ?? '',
        clientDocumentType: data?.customer?.document_type ?? '',
        clientDocumentNumber: data?.customer?.document_id ?? '',
        companyId
      }
    }

    /*
        * Función que maneja la petición de servicio que obtiene la data total del invoice seleccionado
        */
    const getInvoiceDetailData = async (invoice_id: string) => { // eslint-disable-line
      if (validPermittedServices('invoiceDetailData', permissions)) {
        try {
          const response = await invoiceDetailDataService({
            company_id: companyId,
            invoice_id,
            user: reduxUser
          })
          const { data: invoiceData } = response
          const formattedInvoiceData = getFormattedInvoiceData(invoiceData)

          setInvoiceData(formattedInvoiceData)
          setIsLoading(false)
        } catch (error: any) {
          setModalData({ isOpen: true, type: 'conexionError', service: '' })
        };
      } else {
        setModalData({ isOpen: true, type: 'serviceError', service: 'invoiceDetailData' })
      }
    }

    if (invoiceId) getInvoiceDetailData(invoiceId) // eslint-disable-line
  }, [companyId, location, setModalData, timeZoneCode, invoiceId])

  return {
    // States
    isLoading,
    timeZoneCode,
    invoiceData,

    // Functions States

    // Functions
    handleCreateTransactionReport
  }
}

export default useInvoiceDetail
