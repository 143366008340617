import moment from 'moment'
import getCurrencySymbolFromCode from '../money/getCurrencySymbolFromCode'
import getFormattedDate from '../date/getFormattedDate'
import getFormattedMoney from '../money/getFormattedMoney'
import { invoicesTableHeadingsData } from '../../constants/tableData'
import bankData from '../../constants/bankData'
import getCapitalizedString from '../commons/getCapitalizedString'

const getInvoicesTableData = (data: any, timeZoneCode: string) => {
  if (!data) return

  const invoicesTableBodyData = data?.map((item: any) => {
    const formattedDate = moment(getFormattedDate(item?.created_dtm, timeZoneCode))?.format('DD/MM/YYYY HH:mm:ss')

    return {
      invoiceId: item?.invoice_id ?? '',
      externalInvoiceId: item?.external_invoice_id ?? '',
      createdDtm: formattedDate ?? '',
      amount: `${getCurrencySymbolFromCode(item?.currency_cod)} ${getFormattedMoney(item?.total_dcm)}`,
      status: item?.status_cod ?? '',
      bankName: bankData?.[item?.bank_account?.bank_id]?.name ?? getCapitalizedString(item?.bank_account?.bank_name_str) ?? 'OBanking',
      companyId: item?.company_id
    }
  })

  const customerInvoicesTableData = {
    headings: invoicesTableHeadingsData,
    body: invoicesTableBodyData
  }

  return customerInvoicesTableData
}

export default getInvoicesTableData
