import { useAppSelector } from '../../../../store/hooks'
import { useContext, useEffect, useRef, useState } from 'react'

import getFinalPermissions from '../../../../utils/permissions/getFinalPermissions'
import getFormattedGMTtoDMYDate from '../../../../utils/date/getFormattedGMTtoDMYDate'
import getStatementTableData from '../../../../utils/data/getStatementTableData'
import getTimeZoneHours from '../../../../utils/date/getTimeZoneHours'
import getUtcOffHours from '../../../../utils/date/getUtcOffHours'
import statementDataService from '../../../../services/payoutServices/statementDataService'
import statementDetailDataService from '../../../../services/payoutServices/statementDetailDataService'
import validPermittedServices from '../../../../utils/permissions/validPermittedServices'

import ModalContext from '../../../../contexts/ModalContext'

import { InitialCalendarDateData } from '../../../../constants/initialData'
import { downloadDataAsFile } from '../../../../utils/data/downloadDataAsFile'
import { useBasePath } from '../../../../hooks'

const useStatement = () => {
  const basePath = useBasePath()
  const companyData = useAppSelector((state) => state.authReducer.company)
  const reduxUser = useAppSelector(state => state.authReducer.user)
  const servicesRedux = useAppSelector(state => state.authReducer.services)
  const servicesBySubMenuRedux = useAppSelector(state => state.authReducer.servicesBySubMenu)

  const permissions = getFinalPermissions(basePath, servicesRedux, servicesBySubMenuRedux, reduxUser?.role_id ?? '')

  const tableRef = useRef<HTMLDivElement>(null)

  const [nativeData, setNativeData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [offset, setOffset] = useState<number>(0)
  const [, setIsOpenCalendar] = useState<boolean>(false)
  const [isLoadingStatementTable, setIsLoadingStatementTable] = useState<boolean>(true)
  const [calendarDate, setCalendarDate] = useState<any>(InitialCalendarDateData)

  const { setModalData } = useContext(ModalContext)

  const company_id = companyData?.length === 1 ? companyData[0].company_id : undefined // eslint-disable-line
  const timeZoneCode = companyData?.length === 1 ? companyData[0].time_zone_cod : ''

  const timeZoneHours = getTimeZoneHours(timeZoneCode)
  const utcOffHours = getUtcOffHours(timeZoneHours)

  /*
     * Función que maneja la petición de servicio que obtiene la data total que se muestra en la vista de Estado de Cuenta
    */
  const getStatementData = async (isRestarted: boolean, offset: number, calendarDate: any) => {
    if (validPermittedServices('statementData', permissions)) {
      try {
        setIsLoadingStatementTable(true)

        const response = await statementDataService({
          company_id,
          limit_int: 20,
          offset_int: offset,
          ini_dte: calendarDate?.[0],
          fin_dte: calendarDate?.[1],
          orderby: 'request_dtm',
          orderdir_str: 'desc',
          user: reduxUser
        })
        const { data: responseData } = response
        const { data: currentNativeData } = responseData
        let newNativeData = currentNativeData

        if (!isRestarted) {
          newNativeData = nativeData?.concat(currentNativeData)
        };

        const statementTableData = getStatementTableData(newNativeData, timeZoneCode)

        setNativeData(newNativeData)
        setData(statementTableData)
        setIsLoadingStatementTable(false)
      } catch (error: any) {
        setModalData({ isOpen: true, type: 'conexionError', service: '' })
      };
    } else {
      setModalData({ isOpen: true, type: 'serviceError', service: 'statementData' })
    }
  }

  /*
     * Función que maneja la petición de servicio que exporta la data que se muestra en la vista de Estado de Cuenta, en formato excel
    */
  const getStatementDetailData = async (calendarDate: any) => {
    if (validPermittedServices('statementDetailData', permissions)) {
      try {
        const response = await statementDetailDataService({
          company_id,
          ini_dte: calendarDate?.[0],
          fin_dte: calendarDate?.[1],
          user: reduxUser
        })

        downloadDataAsFile({
          data: response.data,
          date: response.headers.date,
          format: 'xlsx',
          name: 'statement-detail',
          utcOffHours
        })
      } catch (error: any) {
        setModalData({ isOpen: true, type: 'conexionError', service: '' })
      };
    } else {
      setModalData({ isOpen: true, type: 'serviceError', service: 'statementDetailData' })
    }
  }

  /*
     * Función que guarda la variable actual del filtro de las fechas seleccionadas en la vista Statement
    */
  const handleCalendarFilterChange = (date: any) => {
    const initialDate = getFormattedGMTtoDMYDate(date?.[0])
    const finalDate = getFormattedGMTtoDMYDate(date?.[1])

    setCalendarDate([initialDate, finalDate])
    setOffset(0)
  }

  const handleStatementExportButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()

    getStatementDetailData(calendarDate) // eslint-disable-line
  }

  const handleScrollStatementTableData = (event: any) => {
    const table = tableRef.current
    if (table) {
      const isBottom = Math.ceil(table.scrollTop) + table.clientHeight === table.scrollHeight
      if (isBottom && data?.body?.length >= offset + 20) {
        const newOffset = offset + 20

        setOffset(newOffset)
        setIsLoadingStatementTable(true)

        getStatementData(false, newOffset, calendarDate) // eslint-disable-line
      };
    }
  }

  useEffect(() => {
    setOffset(0)

    const table = tableRef.current
    if (table) {
      table.scrollTop = 0
    }

    getStatementData(true, 0, calendarDate) // eslint-disable-line
  }, [calendarDate]); // eslint-disable-line

  return {
    // States
    tableRef,
    data,
    timeZoneCode,
    isLoadingStatementTable,

    // Functions States
    setIsOpenCalendar,

    // Functions
    handleCalendarFilterChange,
    handleStatementExportButton,
    handleScrollStatementTableData

  }
}

export default useStatement
